import React, { useContext } from 'react';
import AlertContext from '../../context/alert/alertContext';

const Alert = () => {
	// init context
	const alertContext = useContext(AlertContext),
		{ alert } = alertContext; // destructure alert from alertContext variable

	return (
		alert !== null && (
			<div className={`alert alert-${alert.type}`}>
				<i className="fas fa-info-circle"></i> {alert.msg}
			</div>
		)
	);
};

export default Alert;
