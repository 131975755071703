// rce
import React, { Fragment, useEffect, useContext } from 'react';
import Spinner from '../layout/Spinner';
import Repos from '../repos/Repos';
import { Link } from 'react-router-dom';
import GithubContext from '../../context/github/githubContext';

// destructured props are passed in as parameters
const User = ({ match }) => {
	const githubContext = useContext(GithubContext),
		{ user, loading, getUser, getUserRepos, repos } = githubContext;

	// use effect is a function equivalent to the class componentDidMount() lifecycle method.
	useEffect(() => {
		getUser(match.params.login); // login is from App.js path="/user/:login"
		getUserRepos(match.params.login); // login is from App.js path="/user/:login"
		//we disable [] in the next line because we get an error asking to pass the 2 get functions above. If we do that, useEffect will be looping indefinitely, as will if we don't pass an empty array.
		// eslint-disable-next-line
	}, []);

	// So, when we want to run componentDidMount alternative, we run useEffect and pass an empty array.

	const {
		name,
		avatar_url,
		location,
		bio,
		blog,
		login,
		html_url,
		followers,
		following,
		public_repos,
		hireable,
		company,
	} = user;

	if (loading) {
		return <Spinner />;
	}

	return (
		<Fragment>
			{/* / represents homepage */}
			<Link to="/" className="btnb btn-light">
				Back to Search
			</Link>
			Hireable: {''}
			{hireable ? (
				<i className="fas fa-check text-success" />
			) : (
				<i className="fas fa-times-circle text-danger" />
			)}
			<div className="card grid-2">
				<div className="all-center">
					<img
						src={avatar_url}
						className="round-img"
						alt=""
						style={{ width: '9rem' }}
					/>

					<h1>{name}</h1>
					<p>{location}</p>
				</div>

				<div>
					{/* && = shorthand for if statement */}
					{bio && (
						<Fragment>
							<h3>Bio</h3>
							<p>{bio}</p>
						</Fragment>
					)}

					<a
						href={html_url}
						className="btn btn-dark my-1"
						title="Visit GitHub profile"
					>
						Visit GitHub profile
					</a>

					<ul>
						<li>
							<Fragment>Username: {login}</Fragment>
						</li>

						<li>{company && <Fragment>Company: {company}</Fragment>}</li>

						<li>
							<Fragment>Website: {blog}</Fragment>
						</li>
					</ul>
				</div>
			</div>
			{/* {show followers || nothing } */}
			<div className="card text-center">
				{followers ? (
					<div className="badge badge-primary">Followers: {followers}</div>
				) : (
					''
				)}
				{following && (
					<div className="badge badge-success">Following: {following}</div>
				)}
				{public_repos && (
					<div className="badge badge-danger">Public repos: {public_repos}</div>
				)}
			</div>
			<Repos repos={repos} />
		</Fragment>
	);
};

export default User;
