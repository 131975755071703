// racf - shorthand for react arrow function component
import React, { Fragment } from 'react';
// we can import images because of the way webpack is set under the hood
import spinner from './spinner.gif';

const Spinner = () => {
	return (
		<Fragment>
			<img
				src={spinner}
				alt="Loading spinner"
				style={{ width: '12rem', margin: 'auto', display: 'block' }}
			/>
			..
		</Fragment>
	);
};

export default Spinner;
