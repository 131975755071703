import React, { useContext } from 'react';
import UserItem from './UserItem';
import Spinner from '../layout/Spinner';
// importing it with uppercase G, but initializing with a lowercase
import GithubContext from '../../context/github/githubContext';

// can be written like this:
// const Users = (props) => {
// or destructured:
const Users = () => {
	const githubContext = useContext(GithubContext),
		{ loading, users } = githubContext;

	// if loading, which is being passed through githubContext
	if (loading) {
		return <Spinner />;
	} else {
		return (
			// setting style via variable styled object
			<div style={userStyle}>
				{/* Looping through the users and creating a list */}
				{/*if passing props as an argument: {props.users.map((user) => { */}
				{/* if destructuring: */}
				{users.map((user) => {
					return <UserItem key={user.id} user={user} />;
				})}
			</div>
		);
	}
};

// styling the div via variable
const userStyle = {
	display: 'grid',
	gridTemplateColumns: 'repeat(3, 1fr)',
	gridGap: '1rem',
};

export default Users;
