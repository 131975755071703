import React from 'react'; // rafce

const NotFound = () => {
	return (
		<div>
			<h1>Page Not Found</h1>
			<p className="lead">The page you're looking for doesn't exist.</p>
		</div>
	);
};

export default NotFound;
