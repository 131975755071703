//  initial state
import React, { useReducer } from 'react';
import axios from 'axios';
import githubContext from './githubContext';
import GithubReducer from './githubReducer';
import {
	SEARCH_USERS,
	GET_USER,
	CLEAR_USERS,
	GET_REPOS,
	SET_LOADING,
} from '../types';

// GitHub credentials
// When deploying, credentials are stored on Netlify as environment variables.
const githubCLientId = process.env.REACT_APP_GITHUB_CLIENT_ID,
	githubCLientSecret = process.env.REACT_APP_GITHUB_CLIENT_SECRET;

// GithubState includes all app actions
const GithubState = (props) => {
	const initialState = {
		users: [],
		user: {},
		repos: [],
		loading: false,
	};

	// How does this work? We call an action, make a request to GitHub, receive a response, & then we dispatch a type to the reducer.
	// We use useReducer hook for that.
	const [state, dispatch] = useReducer(GithubReducer, initialState);

	// Search GitHub Users
	const searchUsers = async (text) => {
		setLoading();

		const response = await axios.get(
			`https://api.github.com/search/users?q=${text}&client_id=${githubCLientId}&client_secret=${githubCLientSecret}`,
		);

		dispatch({
			type: SEARCH_USERS,
			payload: response.data.items, // payload is the data that we want to send / response that we receive
		});
	};

	// Get a single GitHub user
	// login is passed 'cause login equals username on GitHub
	const getUser = async (login) => {
		setLoading();

		const response = await axios.get(
			`https://api.github.com/users/${login}?client_id=${githubCLientId}&client_secret=${githubCLientSecret}`,
		);
		dispatch({
			type: GET_USER,
			payload: response.data,
		});
	};

	// Get users repos
	const getUserRepos = async (login) => {
		setLoading(true);

		const response = await axios.get(
			`https://api.github.com/users/${login}/repos?per_page=5&sort=created:asc&client_id=${githubCLientId}&client_secret=${githubCLientSecret}`,
		);

		dispatch({
			type: GET_REPOS,
			payload: response.data,
		});
	};

	// clearUsers from state - executing below attribute from Search component
	const clearUsers = () => {
		dispatch({ type: CLEAR_USERS });
	};

	// Loading - it will dispatch to the reducer. For that, we use dispatch that was pulled from the useReducer hook.
	const setLoading = () => {
		dispatch({ type: SET_LOADING }); // dispatching object with a type
	};

	// We return a Provider with values that we want to be available for the entire app.
	return (
		<githubContext.Provider
			value={{
				users: state.users,
				user: state.user,
				repos: state.repos,
				loading: state.loading,
				searchUsers,
				clearUsers,
				getUser,
				getUserRepos,
			}}
		>
			{props.children}
		</githubContext.Provider>
	);
};

export default GithubState;
