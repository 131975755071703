//  initial state
import React, { useReducer } from 'react';
import AlertContext from './alertContext';
import AlertReducer from './alertReducer';
import { SET_ALERT, REMOVE_ALERT } from '../types';

const AlertState = (props) => {
	// const initialState = {
	// 	alerts: null,
	// };
	// since there's only one state, we can write it like this:
	const initialState = null;

	const [state, dispatch] = useReducer(AlertReducer, initialState);

	// Set Alert (renamed to setAlert because there's no more name conflicts)
	const setAlert = (msg, type) => {
		dispatch({
			type: SET_ALERT,
			payload: { msg, type },
		});

		setTimeout(() => {
			dispatch({ type: REMOVE_ALERT });
		}, 5000);
	};

	// We return a Provider with values that we want to be available for the entire app.
	return (
		<AlertContext.Provider
			value={{
				alert: state, // just state, since we have only one
				setAlert,
			}}
		>
			{props.children}
		</AlertContext.Provider>
	);
};

export default AlertState;
