// reducer - a function that decides what's gonna happen with a state based on an action.
import {
	SEARCH_USERS,
	GET_USER,
	CLEAR_USERS,
	GET_REPOS,
	SET_LOADING,
} from '../types';

const GithubReducer = (state, action) => {
	// This switch has multiple case blocks that evaluates and a default.
	// If action.type === 1st case(SEARCH_USERS), switch starts to execute the code until the end of the switch.
	// If no case is matched then the default code is executed.
	switch (action.type) {
		case SEARCH_USERS:
			return {
				...state, // spread state whatever it is at the moment
				users: action.payload,
				loading: false,
			};

		case SET_LOADING:
			return {
				...state,
				loading: true,
			};

		case CLEAR_USERS:
			return {
				...state,
				users: [],
				loading: false,
			};

		case GET_USER:
			return {
				...state,
				user: action.payload,
				loading: false,
			};

		case GET_REPOS:
			return {
				...state,
				repos: action.payload,
				loading: false,
			};

		default:
			return state;
	}
};

export default GithubReducer;
