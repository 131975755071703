import React, { Fragment } from 'react';

const About = () => {
	return (
		<Fragment>
			<h1>About this app</h1>
			<p>App to search GitHub users</p>
			<p>Version 1.0.0</p>
		</Fragment>
	);
};

export default About;
