// reducer - a function that decides what's gonna happen with a state based on an action.
import { SET_ALERT, REMOVE_ALERT } from '../types';

const AlertReducer = (state, action) => {
	switch (action.type) {
		case SET_ALERT:
			return action.payload; // message and the type

		case REMOVE_ALERT:
			return null; // set it back to null

		default:
			return state;
	}
};

export default AlertReducer;
