// rce - React class export component
import React, { useContext, useState } from 'react';
import GithubContext from '../../context/github/githubContext';
import AlertContext from '../../context/alert/alertContext';

// destructured props are passed in as parameters
const Search = () => {
	const githubContext = useContext(GithubContext),
		alertContext = useContext(AlertContext);

	// Here we have only one piece of state which is text.
	// useState works in a way that we destructure text, and add a method to change that state - let's call it setText.
	const [text, setText] = useState(''); //passing default value blank

	const onChange = (event) => {
		setText(event.target.value);
	};

	const onSubmit = (event) => {
		event.preventDefault();

		// Don't submit if search field is empty:
		if (text === '') {
			alertContext.setAlert('Please enter something.', 'light');
		} else {
			githubContext.searchUsers(text);
			// clear the form after:
			setText('');
		}
	};

	return (
		<div>
			<form onSubmit={onSubmit} className="form">
				<input
					type="text"
					name="text"
					placeholder="Search Users..."
					value={text}
					onChange={onChange}
				/>
				{/* if there were multiple input fields: */}
				{/* <input
					type="text"
					name="email"
					placeholder="Search Users..."
					value={email}
					onChange={onChange}
				/> */}
				<input
					type="submit"
					value="Search"
					className="btn btn-dark btn-block"
				></input>
			</form>

			{/* Conditional - if there's at least 1 user, then show the button */}
			{githubContext.users.length > 0 && (
				<button
					className="btn btn-light btn-block"
					onClick={githubContext.clearUsers}
				>
					Clear
				</button>
			)}
		</div>
	);
};

export default Search;
