// Common convention is to use an uppercase for a file name

// rce - snippet for React / class / export at the bottom
import React from 'react';
// impt - import PropTypes
import PropTypes from 'prop-types';
// Link is not default export like, e.g. Navbar is.
// That's why we use curly braces here, because react-dom exports a bunch of stuff that aren't defaults
import { Link } from 'react-router-dom';

// passing destructuring, gets pulled from defaultProps
const Navbar = ({ icon, title }) => {
	return (
		<nav className="navbar bg-primary">
			{/* We can set a static h1 title here, but it's better to do it with props (properties) */}
			<h1>
				{/* <i className={props.icon}></i>
				{props.title} */}
				{/* We can destrucure like above, but also like below by passing it in the function */}
				<i className={icon}></i> {title}
			</h1>

			<ul>
				<li>
					{/* Link from react-outer-dom is used because a regular <a> tag would refresh a page every time when moving between links */}
					<Link to="/">Home</Link>
				</li>

				<li>
					<Link to="/about">About</Link>
				</li>
			</ul>
		</nav>
	);
};

// In functional components, we set placeholder props & propTypes after the function
// by using them, we leave the option to devs to change it in app.js
Navbar.defaultProps = {
	title: 'GitHub Finder',
	icon: 'fab fa-github',
};

// setting propTypes (forcing type check) to make the app more robust, and less prone to surprises
Navbar.propTypes = {
	title: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired,
};

export default Navbar;
