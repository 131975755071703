// Function based components

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// arrow function can be used, as well as a regular one
// now we don't need render, nor .this since we don't have class anymore

const UserItem = ({ user: { login, avatar_url, html_url } }) => {
	return (
		<div className="card text-center">
			{/* When we wanna grab somethin' from the state within a class, we need: this.state.whatever */}
			<img
				src={avatar_url}
				className="round-img"
				alt="User Avatar"
				style={{ width: '5rem' }}
			/>
			{/* above example of using an inline style */}

			<h3>{login}</h3>
			<Link to={`/user/${login}`} className="btn btn-dark btn-sm my-1">
				Explore more projects
			</Link>
		</div>
	);
};

// adding propTypes for user prop that's passed into the function
UserItem.propTypes = {
	// shorthand = ptor
	user: PropTypes.object.isRequired,
};

export default UserItem;
