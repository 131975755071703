import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Navbar from './components/layout/Navbar';
import User from './components/users/User';
import Alert from './components/layout/Alert';
import Home from './components/pages/Home';
import About from './components/pages/About';
import NotFound from './components/pages/NotFound';
import './App.css';

import GithubState from './context/github/GithubState';
import AlertState from './context/alert/alertState';

const App = () => {
	return (
		// Wrapping everything inside of state
		<GithubState>
			<AlertState>
				{/* In order to use the router, we have to wrap everything inside it */}
				<Router>
					<div className="App">
						{/* <Navbar title="GitHub Finder" icon="fab fa-github" /> */}
						<Navbar />
						{/* by using placeholder props, we leave the option to devs to change it here */}

						<div className="container">
							<Alert />

							{/* Putting multiple components in a single route: */}
							<Switch>
								<Route exact path="/" component={Home}></Route>
								<Route exact path="/about" component={About}></Route>
								<Route exact path="/user/:login" component={User}></Route>
								<Route component={NotFound}></Route>
							</Switch>
						</div>
					</div>
				</Router>
			</AlertState>
		</GithubState>
	);
};

export default App;
